import axios from "axios"
import config from "../config"
import Cookies from "js-cookie"
import { signOut } from "shared/context"

axios.defaults.baseURL = config.baseURLApi
axios.defaults.headers.common["Content-Type"] = "application/json"
const token = localStorage.getItem("token")
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token
}

axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

export const axiosInstance = axios.create({
  baseURL: `${window.location.origin}/api`,
  withCredentials: true,
})

axiosInstance.interceptors.response.use(
  response => {
    return response
  },

  async function (error) {
    let originalRequest = error.config
    if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
      const ref_token = localStorage.getItem("token_ref")
      if (error.response.data.detail === "Invalid token. Please log in again.") {
        signOut()
        return Promise.reject(error)
      }
      return axiosInstance.post("/token/refresh/", { refresh: ref_token }).then(res => {
        const token = res.data.access
        axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token
        originalRequest.headers["Authorization"] = "Bearer " + token
        return axiosInstance(originalRequest)
      })
    } else {
      return Promise.reject(error)
    }
  }
)
